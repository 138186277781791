<template>
  <loader :loading="loading" height="400px">
    <div class="feed">
      <div class="feed--card">
        <div class="feed--card-header">
          <div class="user-details">
            <v-avatar v-if="feed.is_ad" size="32px" color="#F6C15A">
              <span class="white--text">Ad</span>
            </v-avatar>
            <v-avatar
              v-else
              size="32px"
              :color=" feed.user && feed.user.avatar ? 'transparent' : 'primary'"
            >
              <router-link :to="`seller-detail/${feed.user.id}`" v-if="feed.user && feed.user.role && feed.user.role.name === 'seller'" style="width: 100%; height: 100%;">
                <img
                  v-if="feed.user.avatar"
                  :src="feed.user.avatar"
                  :alt="feed.user.name"
                />
                <span v-else class="white--text text-h6 uppercase">{{
                  feed.user.name.substring(0, 2)
                }}</span>
              </router-link>
              <template v-else>
                <img
                  v-if="feed.user.avatar"
                  :src="feed.user.avatar"
                  :alt="feed.user.name"
                />
                <span v-else class="white--text text-h6 uppercase">{{
                  feed.user.name.substring(0, 2)
                }}</span>
              </template>
            </v-avatar>
            <div class="personal-info">
              <p class="name" v-if="feed.user && feed.user.role && feed.user.role.name === 'seller'">
                <router-link :to="`seller-detail/${feed.user.id}`" style="text-decoration: none; color: rgba(0, 0, 0, 0.87);"> {{cardTitle}} </router-link>
              </p>
              <p class="name" v-else>{{ cardTitle }}</p>
              <p class="location" v-if="feed.promoted"> {{ $vuetify.lang.t("$vuetify.promoted_content") }} </p>  
              <p class="location" v-else>{{ cardSubtitle }}</p>
            </div>
          </div>

          <div class="pr-2">
            <div class="pin">
              <v-icon v-if="feed.pin" color="primary">mdi-pin</v-icon>
              <v-menu
                left
                origin="bottom"
                transition="scale-transition"
                v-if="!feed.is_ad"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{
                      `mdi-dots-${feed.is_ad ? "horizontal" : "vertical"}`
                    }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    @click="openReasonModal(feed.id)"
                    v-if="feed.user_id !== $store.state.user.user.id"
                  >
                    <v-list-item-title class="text-subtitle-2">{{
                      $vuetify.lang.t("$vuetify.report_post")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="openReasonModal(feed.user_id, 'user')"
                    v-if="feed.user_id !== $store.state.user.user.id"
                  >
                    <v-list-item-title class="text-subtitle-2">{{
                      $vuetify.lang.t("$vuetify.block_user")
                    }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    v-if="feed.user_id === $store.state.user.user.id"
                    @click="deletePost(feed.id)"
                  >
                    <v-list-item-title>{{
                      $vuetify.lang.t("$vuetify.delete_post")
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>

        <p class="feed--card-caption" v-if="!feed.is_ad">
          {{ feed.details }}
        </p>

        <div class="feed--card-img">
          <Media :media="feed.media" :hide-dots="feed.is_ad" :cover="true" />
        </div>

        <template v-if="!feed.is_ad">
          <div class="feed--card-action">
            <div class="actions">
              <div class="paw icons">
                <v-btn icon @click="$emit('toggle-like', feed.id)">
                  <PawIcon :liked="!!feed.liked" />
                </v-btn>
                <span class="count">{{ feed.total_like }}</span>
              </div>

              <div class="comments icons">
                <v-btn icon @click="postDetails(feed.id)">
                  <img src="@/components/svg/icon/comment-icon.svg" alt="" />
                </v-btn>
                <span class="count">{{ feed.total_comment }}</span>
              </div>
            </div>
            <div class="icons facebook d-flex justify-content-around align-items-center">
              <!-- <v-btn icon @click="facebookResponse(feed.media, feed.details)">
                <img src="@/components/svg/icon/facebook.svg" alt="" />
              </v-btn> -->
              <!-- <button @click="sharePost(feed.id)" style="font-size: 14px;"> Share </button> -->
              <p class="time">{{ feed.time }}</p>
            </div>
          </div>

          <div
            class="feed--card-footer py-2"
            v-if="feed.last_like && feed.total_like > 2"
          >
            <p class="subtitle">
              {{ $vuetify.lang.t("$vuetify.pawed_by") }}
              <span class="bold">{{ feed.last_like }}</span>
              {{ $vuetify.lang.t("$vuetify.and") }}
              <span class="bold">{{ feed.total_like - 1 }} {{ $vuetify.lang.t("$vuetify.others") }}</span>
            </p>
          </div>
        </template>

        <div class="feed--card-action py-3" v-else>
          <p class="ad-text">{{ feed.description }}</p>

          <v-btn
            color="primary"
            :elevation="0"
            class="capitalize"
            @click="ctaAction"
            v-if="feed.cta_links"
          >
            {{ feed.cta_label || "Learn More" }}
          </v-btn>
        </div>
      </div>
      <div class="feed--divider light-bg"></div>
    </div>

    <!-- Reason modal -->
    <v-dialog v-model="reasonModal" persistent max-width="350">
      <v-card>
        <v-card-title class="text-h6 mb-1" v-if="!report_submitted">
          {{
            user_id
              ? $vuetify.lang.t("$vuetify.block_user")
              : $vuetify.lang.t("$vuetify.report_post")
          }}</v-card-title
        >
        <v-card-subtitle v-if="!report_submitted">
          {{
            user_id
              ? $vuetify.lang.t("$vuetify.report_user_detail")
              : $vuetify.lang.t("$vuetify.report_post_detail")
          }}
        </v-card-subtitle>

        <v-card-text v-if="!report_submitted">
          <v-text-field
            :label="$vuetify.lang.t('$vuetify.enter_a_reason')"
            :rules="rules"
            v-model="reason"
            hide-details="auto"
          ></v-text-field>
        </v-card-text>

        <v-card-text v-else>
          <div class="text-center py-5">
            <h6 class="text-h6 mb-4">
              {{ $vuetify.lang.t("$vuetify.thanks_for_reporting") }}
            </h6>
            <p>
              {{ $vuetify.lang.t("$vuetify.review_for_reporting") }}
            </p>
          </div>
        </v-card-text>

        <v-card-actions v-if="!report_submitted">
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="reasonModal = false">
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>

          <v-btn
            color="primary"
            :disabled="!reason"
            :loading="btnLoading"
            text
            @click="handleReport()"
          >
            {{ $vuetify.lang.t("$vuetify.submit") }}
          </v-btn>
        </v-card-actions>

        <v-card-actions v-else class="justify-center">
          <v-btn color="primary" text @click="reportDone()">
            {{ $vuetify.lang.t("$vuetify.done") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </loader>
</template>

<script>
import Media from "@/components/media/media";
import PawIcon from "@//components/svg/icon/paw-icon";
import placeholderImage from "@/mixin/placeholder-image";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("feed");
import { BASE_URL } from "@/config";
export default {
  mixins: [placeholderImage],
  components: { Media, PawIcon },
  props: {
    feed: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      reasonModal: false,
      post_id: null,
      user_id: null,
      rules: [(value) => !!value || "Required."],
      reason: "",
      report_submitted: false,
      btnLoading: false,
    };
  },
  computed: {
    cardTitle() {
      const { is_ad, user, title } = this.feed;
      if (!is_ad) return user.name;
      return title;
    },
    cardSubtitle() {
      const { is_ad, user } = this.feed;
      if (!is_ad) return user.country;
      return this.$vuetify.lang.t("$vuetify.sponsored_content");
    },
      },
  methods: {
    ...mapActions(["DELETE_USER_POST", "REPORT_FEED", "REFETCH_FEED", "FETCH_FACEBOOK", "GET_FACEBOOK_AUTH", "MAKE_POST_PUBLIC"]),
    postDetails(id) {
      const { name } = this.$route;
      if (name !== "single-post-view") {
        this.$router.push(`/post/${id}`);
      }
    },
   
    facebookResponse(imageURL, message){
      this.FETCH_FACEBOOK({
        image_url: imageURL[0],
        message: message,
      });
    },
  
    async deletePost(post_id) {
      const { name } = this.$route;
      this.loading = true;
      if (name !== "single-post-view") {
        await this.DELETE_USER_POST({ post_id, feedList: true });
      } else {
        await this.DELETE_USER_POST({ post_id, feedList: false });
        this.$router.push(`/`);
      }
      this.loading = false;
    },

    openReasonModal(id, user) {
      this.reasonModal = true;
      if (user) {
        this.user_id = id;
        this.post_id = null;
      } else {
        this.post_id = id;
        this.user_id = null;
      }
    },

    async handleReport() {
      this.btnLoading = true;
      const data = {};
      if (this.user_id) {
        data.block_user = this.user_id;
      } else {
        data.post_id = this.post_id;
      }
      data.reported_detail = this.reason;
      this.REPORT_FEED(data).then(() => {
        this.report_submitted = true;
        this.btnLoading = false;
        this.reason = "";
      });
    },

    ctaAction() {
      if (this.feed.cta_links) {
        window.open(this.feed.cta_links, "_system", "location=yes");
      }
    },

    reportDone() {
      this.REFETCH_FEED();
      this.reasonModal = false;
      this.report_submitted = false;
    },

    sharePost(postID) {
      this.MAKE_POST_PUBLIC({
        post_id: postID
      }).then(() => {
        let URL = `${BASE_URL}/post/public/${postID}`;
        let facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(URL);
        window.open(facebookShareUrl, '_blank');
      });
    }
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
$spacer: 0.6rem;

.feed {
  &--card {
    background: #fff;
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $spacer;
      padding-right: 0;
      .user-details {
        display: flex;
        align-items: center;

        .personal-info {
          margin-left: $spacer;
          .name {
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
          }
          .location {
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.07px;
            color: #262626;
          }
        }
      }
    }

    &-caption {
      padding: 0 $spacer;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.7);
    }

    &-img {
      padding: $spacer;
      padding-bottom: 0;
    }

    &-action {
      padding: 0 $spacer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -1rem;

      .actions {
        display: flex;
        align-items: center;

        .comments {
          margin-left: $spacer;
        }
    
       
        .icons {
          display: flex;
          align-items: Center;

          .count {
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.05px;
            color: #000000;
            margin-left: 4px;
            display: inline-block;
          }
        }
      }

      .time {
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.05px;
        color: rgba(0, 0, 0, 0.4);
      }
    }

    &-footer {
      padding: 0 $spacer;
      padding-bottom: $spacer;
      .subtitle {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.07px;
        color: #262626;

        .bold {
          font-weight: bold;
        }
      }
    }
  }

  &--divider {
    height: 8px;
    width: 100%;
  }
}

p.ad-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
  opacity: 0.5;
}
 .facebook{
          gap: 1rem;
        }
        .facebook img{
          height: 1.5rem;
          width: 1.5rem;
        }
</style>
